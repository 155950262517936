import * as Apollo from '@apollo/client'
import {
  ApolloError,
  FetchResult,
  MutationResult,
  QueryResult
} from '@apollo/client'
import { useBlockUi } from 'components/BlockUi'
import uniq from 'lodash/uniq'
import useToast from 'hooks/useToast'
import useConfirm from 'hooks/useConfirm'
import { ConfirmArgs } from 'components/ConfirmDialog'
import { useRef, useEffect } from 'react'
import isArray from 'lodash/isArray'
import flatten from 'lodash/flatten'
import useDeepCompareCallback from 'hooks/useDeepCompareCallback'

import getMutationError from './getMutationError'

export * from '@apollo/client'

interface Notifications {
  onSuccess?:
    | {
        title?: string
        message: string
      }
    | string
  onError?:
    | {
        title?: string
        message?: string
      }
    | string
    | boolean
}

export type QueryHookOptions<TData, TVariables> = Apollo.QueryHookOptions<
  TData,
  TVariables
> & {
  notifications?: Notifications
}

type MutationFunctionOptions<
  TData,
  TVariables
> = Apollo.MutationFunctionOptions<TData, TVariables> & {
  notifications?: Notifications
  confirm?: ConfirmArgs | string
  blockUi?: boolean
}

export function useQuery<
  TData = unknown,
  TVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const openToast = useToast()
  return Apollo.useQuery<TData, TVariables>(query, {
    ...options,
    onCompleted: (data) => {
      if (options?.notifications?.onSuccess) {
        openToast({
          ...successMessageOptions(options),
          variant: 'success'
        })
      }
      if (options?.onCompleted) options.onCompleted(data)
    },
    onError: (e) => {
      if (options?.notifications?.onError) {
        openToast({
          ...errorMessageOptions(options, getSystemError(e)),
          variant: 'error'
        })
      }
      if (options?.onError) options.onError(e)
    }
  })
}

export type MutationHookOptions<TData, TVariables> = Apollo.MutationHookOptions<
  TData,
  TVariables
> & {
  notifications?: Notifications
  confirm?: ConfirmArgs | string
  blockUi?: boolean
}

export function useMutation<
  TData = unknown,
  TVariables = Apollo.OperationVariables
>(
  mutation: Apollo.DocumentNode,
  options: MutationHookOptions<TData, TVariables> = {}
): [
  (
    mutationOptions?: MutationHookOptions<TData, TVariables>
  ) => Promise<FetchResult<TData> | null>,
  MutationResult<TData>
] {
  const blockUi = useBlockUi()
  const blockUiRef = useRef(blockUi)
  useEffect(() => {
    blockUiRef.current = blockUi
  }, [blockUi])

  const toast = useToast()
  const toastRef = useRef(toast)
  useEffect(() => {
    toastRef.current = toast
  }, [toast])

  const confirmation = useConfirm()

  const confirmationRef = useRef(confirmation)
  useEffect(() => {
    confirmationRef.current = confirmation
  }, [confirmation])

  const [onMutate, mutationResult] = Apollo.useMutation<TData, TVariables>(
    mutation,
    {
      ...options,
      onCompleted: () => {},
      onError: () => {}
    }
  )

  const onMutateWrapper = useDeepCompareCallback(
    async (
      mutationOptions: MutationFunctionOptions<TData, TVariables> = {}
    ) => {
      const actualConfirm = mutationOptions.confirm ?? options.confirm
      if (actualConfirm) {
        if (
          !(await confirmationRef.current(makeConfirmOptions(actualConfirm)))
        ) {
          return null
        }
      }
      const actualBlockUi = mutationOptions.blockUi ?? options.blockUi
      try {
        if (actualBlockUi) {
          blockUiRef.current.startBlockUi()
        }
        const response = await onMutate(mutationOptions)
        const error = getMutationError(response)
        if (error) {
          const onError = mutationOptions?.onError ?? options?.onError
          if (onError) {
            onError(new ApolloError({ errorMessage: error }))
          }
          const onErrorNotification =
            mutationOptions?.notifications?.onError ??
            options?.notifications?.onError
          if (onErrorNotification) {
            toastRef.current({
              ...errorMessageOptions(options, error),
              variant: 'error'
            })
            return null
          }
          return response
        }
        const onSuccessNotification =
          mutationOptions?.notifications?.onSuccess ??
          options?.notifications?.onSuccess
        if (onSuccessNotification) {
          toastRef.current({
            ...successMessageOptions(options),
            variant: 'success'
          })
        }

        const onCompleted = mutationOptions?.onCompleted ?? options?.onCompleted
        if (onCompleted) onCompleted(response.data!)

        return response
      } finally {
        if (actualBlockUi) {
          blockUiRef.current.stopBlockUi()
        }
      }
    },
    [options, blockUiRef, confirmationRef, toastRef]
  )
  return [onMutateWrapper, mutationResult]
}

function successMessageOptions(options: {
  notifications?: Notifications
}): { title: string; message: string } {
  let title: string
  let message: string
  if (typeof options?.notifications?.onSuccess === 'string') {
    title = 'Success'
    message = options?.notifications?.onSuccess
  } else {
    title = options?.notifications?.onSuccess?.title || 'Success'
    message = options?.notifications?.onSuccess?.message!
  }
  return {
    title,
    message
  }
}

function errorMessageOptions(
  options: { notifications?: Notifications },
  errorMessage: string
): { title: string; message: string } {
  let title: string
  let message: string
  if (typeof options?.notifications?.onError === 'string') {
    title = 'Error'
    message = options?.notifications?.onError
  } else if (typeof options?.notifications?.onError === 'boolean') {
    title = 'Error'
    message = errorMessage
  } else {
    title = options?.notifications?.onError?.title || 'Error'
    message = options?.notifications?.onError?.message || errorMessage
  }
  return { title, message }
}

function makeConfirmOptions(input: ConfirmArgs | string): ConfirmArgs {
  if (typeof input === 'string') {
    return {
      title: 'Are you sure?',
      message: input,
      okButton: 'Confirm',
      cancelButton: 'Cancel'
    }
  }
  return input
}

function getSystemErrorMessages(err: ApolloError): string[] {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const networkErrors = (
    (err?.networkError as any)?.result?.errors ?? ([] as { message: string }[])
  ).map((e: Error) => e.message)
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const networkError = networkErrors.length ? null : err?.networkError?.message
  const gqlErrors = err?.graphQLErrors?.map((i) => i.message) || []
  return [...networkErrors, err.message, networkError, ...gqlErrors]
    .filter((e) => e && e.length > 0)
    .map((error) => {
      if (error.match(/failed to fetch/i)) {
        return "There's a network connection problem"
      }
      return error
    })
}

export function getSystemError(err: ApolloError | ApolloError[]): string {
  const errors: ApolloError[] = isArray(err)
    ? (err as ApolloError[])
    : [err as ApolloError]
  const messages = uniq(flatten(errors.map(getSystemErrorMessages)))
  return messages.join(', \n').trim()
}

import React from 'react'
import { FormSpy } from 'react-final-form'
import NoticeBlock from 'components/NoticeBlock'

type Props = {
  title?: string
  error?: string
  errorKey?: string
  className?: string
  showWhenDirty?: boolean
}

const FormError = ({
  error: manualError,
  className,
  errorKey,
  showWhenDirty = false,
  title = 'Error'
}: Props) => {
  if (errorKey) {
    return (
      <FormSpy
        subscription={{ errors: true, submitErrors: true, dirtyFields: true }}
      >
        {({ errors, submitErrors, dirtyFields }) => {
          const allErrors = { ...errors, ...submitErrors }
          const error = allErrors[errorKey]
          if (!error) return null
          if (showWhenDirty && !dirtyFields[errorKey]) return null

          return (
            <div className={className}>
              <NoticeBlock message={error} title={title} variant="danger" />
            </div>
          )
        }}
      </FormSpy>
    )
  }
  if (manualError) {
    return (
      <div className={className}>
        <NoticeBlock message={manualError} title={title} variant="danger" />
      </div>
    )
  }
  return (
    <FormSpy subscription={{ submitError: true }}>
      {({ submitError }) =>
        submitError ? (
          <div className={className}>
            <NoticeBlock message={submitError} title={title} variant="danger" />
          </div>
        ) : null
      }
    </FormSpy>
  )
}

export default FormError

// based on https://github.com/kentcdodds/use-deep-compare-effect

import * as React from 'react'
import isEqual from 'lodash/isEqual'

type UseCallbackParams = Parameters<typeof React.useCallback>
type EffectCallback = UseCallbackParams[0]
type DependencyList = UseCallbackParams[1]
// yes, I know it's void, but I like what this communicates about
// the intent of these functions: It's just like useEffect
type UseCallbackReturn = ReturnType<typeof React.useCallback>

function checkDeps(deps: DependencyList) {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.'
    )
  }
}
/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
export function useDeepCompareMemoize<T>(value: T) {
  const ref = React.useRef<T>(value)
  const signalRef = React.useRef<number>(0)

  if (!isEqual(value, ref.current)) {
    ref.current = value
    signalRef.current += 1
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => ref.current, [signalRef.current])
}

function useDeepCompareCallback(
  callback: EffectCallback,
  dependencies: DependencyList
): UseCallbackReturn {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareCallback

import React, { useContext } from 'react'
import cn from 'classnames'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

export type NoticeVariant = 'info-secondary' | 'danger' | 'info' | 'success'

type Props = {
  title?: string | null
  message?: string | React.ReactElement
  variant: NoticeVariant
  className?: string
  size?: 'md' | 'lg'
}

export default function NoticeBlock({
  message,
  title,
  variant,
  className,
  size = 'md'
}: Props) {
  const { albertsons } = useContext(ShiftPageContext)
  return (
    <div
      className={cn(
        'border border-l-4 border-solid rounded-r-lg text-sm',
        {
          'border-orange-500': variant === 'info' && !albertsons,
          'border-blue-500': variant === 'info-secondary' && !albertsons,
          'border-green-500': variant === 'success' && !albertsons,
          'border-red-500': variant === 'danger' && !albertsons,
          'border-albertsons-secondary4': variant === 'info' && albertsons,
          'border-albertsons-pink-lemonade': variant === 'danger' && albertsons,
          'p-2 pl-4': size === 'md',
          'p-6 pl-6': size === 'lg'
        },
        className
      )}
    >
      {title && (
        <p
          className={cn('uppercase font-medium tracking-wide', {
            'text-orange-900': variant === 'info' && !albertsons,
            'text-red-900': variant === 'danger' && !albertsons,
            'text-green-900': variant === 'success' && !albertsons,
            'text-blue-800': variant === 'info-secondary' && !albertsons,
            'text-albertsons-jam': albertsons,
            'text-base': size === 'lg',
            'mb-2': message
          })}
        >
          {title}
        </p>
      )}
      {message && <p className="text-gray-700 font-light">{message}</p>}
    </div>
  )
}

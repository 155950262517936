import { FORM_ERROR } from 'final-form'
import { FetchResult } from '@apollo/client'

import getMutationErrors from './getMutationErrors'

export default function getMutationError<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MutationData extends Record<string, any>
>(response: FetchResult<MutationData>): string | null {
  // @ts-ignore
  const errorsHash = getMutationErrors(response)
  return errorsHash ? (errorsHash[FORM_ERROR] as string) || null : null
}
